

const dashboards = ref([]);
const dashboardsCount = ref(0);

export function useDashboards() {
  const { toast, $repository } = useNuxtApp();
  const { data: admin } = useAuth();
  async function fetchDashboards(force = false) {
    if (dashboards.value.length === 0 || force) {
      await updateDashboards(admin.value.id);
    }
  }
  async function updateDashboards(id) {
    const getDashboards = await $repository.customDashboard.getDashboards(id);
    if (getDashboards && getDashboards.success) {
      dashboards.value = getDashboards.dashboards;
      dashboardsCount.value = getDashboards.dashboards.length;
    } else {
      toast.showError("Dashboards", getDashboards.info);
      dashboards.value = [];
      dashboardsCount.value = 0;
    }
  }

  return { fetchDashboards, dashboards, dashboardsCount };
}
